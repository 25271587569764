<template>
    <div>
        <el-form label-width="140px" :model="form" :rules="rules" ref="ruleForm">
            <el-form-item label="同期回数" prop="id">
                <el-input v-int v-model="form.count" type="text" style="margin-bottom: 8px;"
                          placeholder="同期回数"></el-input>
            </el-form-item>
            <el-form-item label="メール通知" prop="id">
                <template v-for="(item, index) in form.mails">
                    <el-input type="text" v-model="form.mails[index]" :disabled="index < form.mails.length - 1"
                              style="margin-bottom:8px;"
                              :key="index"></el-input>
                    <span class="del-box" @click="deleteName(index)">
                        <i class="el-icon-delete"></i>削除
                    </span>
                </template>
                <span class="add-box">
                  <span @click="addName">+ ほかのメール</span>
                </span>
            </el-form-item>
        </el-form>
        <span class="btn-footer">
            <el-button class=" btn-width-big" @click="cancel" icon="el-icon-back">キャンセル</el-button>
            <el-button class=" btn-width-big" type="success" icon="el-icon-check" @click="configSave">保存</el-button>
      </span>
    </div>
</template>

<script>
    export default {
        name: "scheduledAdd",
        data() {

            return {
                name: '',
                form: {
                    count: '',
                    mails: [],
                },
                rules: {},
                configs: []
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            // TODO 中文
            /**
             * 获取配置数据
             **/
            async getData() {
                let res = await this.$http.sys.sysConfig();
                if (res.code === 200) {
                    this.configs = res.rows;
                    let item = this.configs.find(item => item.configKey === 'task.exec.failed.retryCount')
                    this.form.count = item.configValue;
                    let item1 = this.configs.find(item => item.configKey === 'task.exec.failed.sendMail')
                    if (item1 && item1.configValue) {
                        this.form.mails = JSON.parse(item1.configValue)
                    }
                    if (!this.form.mails || !this.form.mails.length) {
                        this.form.mails = ['']
                    }
                }
            },
            // TODO 中文
            /**
             * 邮箱check
             **/
            checkEmail(email) {
                var myReg = /^[a-zA-Z0-9]+((_|-|\.)[a-zA-Z0-9]+)*@([a-zA-Z0-9]+(-|\.))+[a-zA-Z]{2,5}$/;
                return myReg.test(email);
            },
            // TODO 中文
            /**
             * 增加邮箱
             **/
            addName() {
                if (!this.jurMails()) {
                    return;
                }
                (!this.form.mails.length || this.form.mails[this.form.mails.length - 1])
                && this.form.mails.push('');
            },
            // TODO 中文
            /**
             * 邮箱校验
             **/
            jurMails(){
                let nowEml = this.form.mails[this.form.mails.length - 1];
                if (!nowEml) {
                    this.$message.warning("メールをご記入ください")
                    return false;
                }
                if (!this.checkEmail(nowEml)) {
                    this.$message.warning(  "メール ["+nowEml+"] のフォーマットが間違っている")
                    return false;
                }
                return true;
            },
            // TODO 中文
            /**
             * 削除邮箱
             * @param index
             */
            deleteName(index) {
                this.form.mails.splice(index, 1);
                if (!this.form.mails || !this.form.mails.length) {
                    this.form.mails = ['']
                }
            },
            // TODO 中文
            /**
             * 取消
             */
            cancel() {
                this.$emit('cancel')
            },
            // TODO 中文
            /**
             * 保存
             * @return {Promise<void>}
             */
            async configSave() {
                if (!this.form.count) {
                    this.$message.warning("同期回数")
                    return;
                }
                if (!this.form.mails || !this.form.mails.length) {
                    this.$message.warning("メール通知")
                    return;
                }
                if (!this.jurMails()) {
                    return ;
                }
                let item = this.configs.find(item => item.configKey === 'task.exec.failed.retryCount')
                item['configValue'] = this.form.count;
                await this.$http.sys.sysConfigUpdate(item)
                item = this.configs.find(item => item.configKey === 'task.exec.failed.sendMail')
                item['configValue'] = JSON.stringify(this.form.mails);
                await this.$http.sys.sysConfigUpdate(item)
                this.$emit('configSave')
            }
        }
    }
</script>

<style lang="scss" scoped>
  .del-box {
    display: inline-block;
    color: rgba(217, 0, 27, 0.996078431372549) !important;;
    margin-left: 8px;
    cursor: pointer;
  }

  .add-box {
    display: block;
    color: rgba(24, 144, 255, 1) !important;

    span {
      cursor: pointer;
    }
  }

  .el-form-item {
    text-align: left;
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }

  .el-input {
    width: 70%;
  }
</style>
