<template>
    <div class="content3">
        <el-row class="sub-box" style="">
            <el-button v-has-permi="['system:task:settings']" type="primary" icon="el-icon-s-tools" @click="add">設定
            </el-button>
            <el-button type="primary" @click="toBatch">バッチ実行記録
            </el-button>
        </el-row>
        <el-table v-has-permi="['system:task:query']" :data="tableData" style="width: 100%" :max-height="tableHeight">
            <el-table-column align="center" prop="jobName" label="タスク名称" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="実行時間" width="130">
                <template slot-scope="scope">
              <span>
                  {{ getCorn(scope.row) }}
              </span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="lastExecTime" label="最終実行時間" width="180">
            </el-table-column>
            <el-table-column align="center" label="状態" width="80">
                <template slot-scope="scope">
                    <el-switch style="display: block" v-model="scope.row.status" active-color="#13ce66"
                               @change="changeStatus(scope.row)"
                               inactive-color="rgba(242, 242, 242, 1)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="remark" label="備考" width="200">
                <template slot-scope="scope">
                    <cus-tooltip :val="scope.row.remark"></cus-tooltip>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="cz-box" style="display: flex;justify-content: center">
                        <el-button v-has-permi="['system:task:exec']" class="small-btn" type="primary" size="small"
                                   @click="jobRun(scope.row)">手動実行
                        </el-button>
                        <el-button v-has-permi="['system:task:edit']" class="small-btn" type="primary" size="small"
                                   @click="editInfo(scope.row)">編集
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-has-permi="['system:task:query']" class="pagina-box" background layout="prev, pager, next"
                       :total="total" :current-page="pageNo"
                       :page-sizes="pagesizes"
                       :page-size="pagesize"
                       @current-change="onPage"
                       @size-change="onSizeChange">
        </el-pagination>
        <el-dialog :title="s_title" :visible.sync="dialogVisible" width="30%"
                   :close-on-click-modal="false" :show-close="false">
            <scheduled-add v-if="dialogVisible" @cancel="dialogVisible = false"
                           @configSave="dialogVisible = false"></scheduled-add>
        </el-dialog>
        <el-dialog title="タスクを編集" :visible.sync="dialogVisibleEdit" width="30%"
                   :close-on-click-modal="false" :show-close="false">
            <scheduled-edit v-if="dialogVisibleEdit" :data="editRow" @cancel="dialogVisibleEdit = false"
                            @save="schSave"></scheduled-edit>
        </el-dialog>
    </div>
</template>

<script>
    import scheduledAdd from "./scheduled-add"
    import scheduledEdit from "./scheduled-edit"
    import CusTooltip from "../../components/CusTooltip";
    export default {
        name: "scheduled",
        components: {scheduledAdd, scheduledEdit, CusTooltip},
        data() {
            return {
                dateArr: [],
                dayRule: '',
                dayRuleSup: '',
                editRow: {},
                pageNo: 1,
                pagesizes: [10, 20, 30, 50, 100, 200, 500],
                pagesize: 10,
                total: 0,
                s_title: '',
                types: '',
                tableHeight: 0,
                dialogVisible: false,
                dialogVisibleEdit: false,
                tableData: [],
                form: {
                    pageNum: 1,
                    pageSize: 10
                },
                cronList: []
            }
        },
        mounted() {
            this.cronList = this.$util.cornList();
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            this.getTableMaxHeight();
            let _this = this;
            window.onresize = function () {//テーブルを高度に適応させるための方法
                _this.getTableMaxHeight();//容器の現在の高さを取得し、テーブルの最大高さをリセットする
            }
            this.getList();
        },
        methods: {
            getCorn(row) {
                let corn = this.cronList.find(item => {
                    return item.value === row.cronExpression
                });
                return corn && corn.label || '';
            },
            /**
             * 編集保存成功
             */
            schSave() {
                this.dialogVisibleEdit = false
                this.getList();
            },
            onPage(num) {
                this.pageNo = num;
                this.getList()
            },
            // ページ毎の表示数変更イベント
            onSizeChange(size) {
                this.pageNo = 1;
                this.pagesize = size;
                this.getList()
            },
            /**
             * リストデータを取得する
             * @returns {Promise<void>}
             */
            async getList() {
                this.form.pageNum = this.pageNo;
                this.form.pageSize = this.pagesize;
                let res = await this.$http.sys.jobList(this.form);
                this.tableData = [];
                if (res.code === 200) {
                    res.rows.forEach(item => {
                        item.status = item.status === '0'
                        let times = this.expressionJur(item.cronExpression)
                        let index = this.cronList.findIndex(sub => { return sub.value === item.cronExpression})
                        if (index == 0) {
                            item['nextTime'] =
                                this.dayjs(new Date(times).getTime()).subtract(15, 'minute').format('YYYY-MM-DD HH:mm:ss')
                        } else if (index == 1) {
                            item['nextTime'] =
                                this.dayjs(new Date(times).getTime()).subtract(30, 'minute').format('YYYY-MM-DD HH:mm:ss')
                        } else if (index == 2) {
                            item['nextTime'] =
                                this.dayjs(new Date(times).getTime()).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
                        } else if (index == 3) {
                            item['nextTime'] =
                                this.dayjs(new Date(times).getTime()).subtract(2, 'hour').format('YYYY-MM-DD HH:mm:ss')
                        }else {
                            item['nextTime'] =
                                this.dayjs(new Date(times).getTime()).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
                        }
                        this.tableData.push(item);
                    })
                    this.total = res.total;
                }
            },
            /**
             * toBatch
             **/
            toBatch(){
                this.$router.push("/batch")
            },
            /**
             * 設定
             */
            add() {
                this.s_title = 'タスクを設定';
                this.types = 'add';
                this.dialogVisible = true;
            },
            editInfo(row) {
                this.editRow = this.$util.cloneDeep(row);
                this.dialogVisibleEdit = true;
            },
            /**
             * 状態を切り替える
             * @param row
             * @returns {Promise<void>}
             */
            async changeStatus(row) {
                let res = await this.$http.sys.jobChangeStatus({
                    jobId: row.jobId,
                    status: row.status ? '0' : '1'
                })
                if (res.code == 200) {
                    this.$message.success(res.msg)
                }
            },
            async jobRun(row) {
                let res = await this.$http.batchRecord.jobRun({jobId: row.jobId});
                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.getList();
                }
            },
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            getTableMaxHeight() {
                this.$nextTick(function () {
                    let box = document.querySelector(".content3").attributes
                    let box_clientHeight = box[0].ownerElement.clientHeight;
                    this.tableHeight = box_clientHeight - 120;
                })
            },
            // 式の値が変ったら、結果を計算し初める
            expressionJur(cron) {
                if (!cron) {
                    return '';
                }
                this.dateArr = [];
                this.dayRule = '';
                this.dayRuleSup = '';
                // 規則的な配列を取る[0秒、1分、2時、3日、4月、5週間、6年]
                let ruleArr = cron.split(' ');
                // 循環に入った回数を記録する
                let nums = 1;
                // 記号時間規則の結果を一時的に保存するための配列
                let resultArr = [];
                // [年、月、日、时、分、秒]まで正確な現在時刻を取得する
                let nTime = new Date();
                let nYear = nTime.getFullYear();
                let nMonth = nTime.getMonth() + 1;
                let nDay = nTime.getDate();
                let nHour = nTime.getHours();
                let nMin = nTime.getMinutes();
                let nSecond = nTime.getSeconds();
                // ルールによって、100年間で可能な年配列、月配列などを取得できます
                this.getSecondArr(ruleArr[0]);
                this.getMinArr(ruleArr[1]);
                this.getHourArr(ruleArr[2]);
                this.getDayArr(ruleArr[3]);
                this.getMonthArr(ruleArr[4]);
                this.getWeekArr(ruleArr[5]);
                this.getYearArr(ruleArr[6], nYear);
                // 取得した配列に値を付けるー使いやすい
                let sDate = this.dateArr[0];
                let mDate = this.dateArr[1];
                let hDate = this.dateArr[2];
                let DDate = this.dateArr[3];
                let MDate = this.dateArr[4];
                let YDate = this.dateArr[5];
                // 現在時刻のインデックスを配列で取得する
                let sIdx = this.getIndex(sDate, nSecond);
                let mIdx = this.getIndex(mDate, nMin);
                let hIdx = this.getIndex(hDate, nHour);
                let DIdx = this.getIndex(DDate, nDay);
                let MIdx = this.getIndex(MDate, nMonth);
                let YIdx = this.getIndex(YDate, nYear);
                // 月日時分秒の関数をリセットする(後ろの方が使う多い)
                const resetSecond = function () {
                    sIdx = 0;
                    nSecond = sDate[sIdx]
                }
                const resetMin = function () {
                    mIdx = 0;
                    nMin = mDate[mIdx]
                    resetSecond();
                }
                const resetHour = function () {
                    hIdx = 0;
                    nHour = hDate[hIdx]
                    resetMin();
                }
                const resetDay = function () {
                    DIdx = 0;
                    nDay = DDate[DIdx]
                    resetHour();
                }
                const resetMonth = function () {
                    MIdx = 0;
                    nMonth = MDate[MIdx]
                    resetDay();
                }
                // 現在の“年”配列の現在の値でない場合
                if (nYear !== YDate[YIdx]) {
                    resetMonth();
                }
                // 現在の“月”が配列の現在の値でない場合
                if (nMonth !== MDate[MIdx]) {
                    resetDay();
                }
                // 現在の“日”が配列の現在の値でない場合
                if (nDay !== DDate[DIdx]) {
                    resetHour();
                }
                // 現在の“時”が配列の現在の値でない場合
                if (nHour !== hDate[hIdx]) {
                    resetMin();
                }
                // 現在の“分”が配列の現在の値でない場合
                if (nMin !== mDate[mIdx]) {
                    resetSecond();
                }

                // 循環年配列
                goYear: for (let Yi = YIdx; Yi < YDate.length; Yi++) {
                    let YY = YDate[Yi];
                    // 最大値になったの時に
                    if (nMonth > MDate[MDate.length - 1]) {
                        resetMonth();
                        continue;
                    }
                    // 循環月配列
                    goMonth: for (let Mi = MIdx; Mi < MDate.length; Mi++) {
                        // 赋值、後で使いやすい
                        let MM = MDate[Mi];
                        MM = MM < 10 ? '0' + MM : MM;
                        // 最大値になったの時に
                        if (nDay > DDate[DDate.length - 1]) {
                            resetDay();
                            if (Mi == MDate.length - 1) {
                                resetMonth();
                                continue goYear;
                            }
                            continue;
                        }
                        // 循環日配列
                        goDay: for (let Di = DIdx; Di < DDate.length; Di++) {
                            // 赋值、後で使いやすい
                            let DD = DDate[Di];
                            let thisDD = DD < 10 ? '0' + DD : DD;

                            // 最大値になったの時に
                            if (nHour > hDate[hDate.length - 1]) {
                                resetHour();
                                if (Di == DDate.length - 1) {
                                    resetDay();
                                    if (Mi == MDate.length - 1) {
                                        resetMonth();
                                        continue goYear;
                                    }
                                    continue goMonth;
                                }
                                continue;
                            }

                            // 日付の合法性を判断し、合法でない場合は現在の循環を終了します
                            if (this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true && this.dayRule !== 'workDay' && this.dayRule !== 'lastWeek' && this.dayRule !== 'lastDay') {
                                resetDay();
                                continue goMonth;
                            }
                            // 日付ルールに値がある場合
                            if (this.dayRule == 'lastDay') {
                                // 合法でない場合は、合法月末の最終日に調整する

                                if (this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                    while (DD > 0 && this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                        DD--;

                                        thisDD = DD < 10 ? '0' + DD : DD;
                                    }
                                }
                            } else if (this.dayRule == 'workDay') {
                                // 校验并调整如果是2月30号这种日期传进来时需调整至正常月底
                                if (this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                    while (DD > 0 && this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                        DD--;
                                        thisDD = DD < 10 ? '0' + DD : DD;
                                    }
                                }
                                // 条件取得日は星期X
                                let thisWeek = this.formatDate(new Date(YY + '-' + MM + '-' + thisDD + ' 00:00:00'), 'week');
                                // 日曜日の時
                                if (thisWeek == 1) {
                                    // 先ず日が探して，月末かどうかを判断します
                                    DD++;
                                    thisDD = DD < 10 ? '0' + DD : DD;
                                    // 次の日は既に合法日付ではないと判断する
                                    if (this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                        DD -= 3;
                                    }
                                } else if (thisWeek == 7) {
                                    // 土曜日なら１日でないと判断れば操作できます
                                    if (this.dayRuleSup !== 1) {
                                        DD--;
                                    } else {
                                        DD += 2;
                                    }
                                }
                            } else if (this.dayRule == 'weekDay') {
                                // 何曜日が指定されていれば
                                // 今日の日付を取得する
                                let thisWeek = this.formatDate(new Date(YY + '-' + MM + '-' + DD + ' 00:00:00'), 'week');
                                // 当週間が週間池（dayRuleSup）の中にあるかどうかチェックする
                                if (this.dayRuleSup.indexOf(thisWeek) < 0) {
                                    // 最大値になったの時に
                                    if (Di == DDate.length - 1) {
                                        resetDay();
                                        if (Mi == MDate.length - 1) {
                                            resetMonth();
                                            continue goYear;
                                        }
                                        continue goMonth;
                                    }
                                    continue;
                                }
                            } else if (this.dayRule == 'assWeek') {
                                // 何週目の何曜日が指定されていれば
                                // 毎月１日が何曜日を取得する
                                let thisWeek = this.formatDate(new Date(YY + '-' + MM + '-' + DD + ' 00:00:00'), 'week');
                                if (this.dayRuleSup[1] >= thisWeek) {
                                    DD = (this.dayRuleSup[0] - 1) * 7 + this.dayRuleSup[1] - thisWeek + 1;
                                } else {
                                    DD = this.dayRuleSup[0] * 7 + this.dayRuleSup[1] - thisWeek + 1;
                                }
                            } else if (this.dayRule == 'lastWeek') {
                                // 毎月最後の曜日が指定されていれば
                                // 校验并调整如果是2月30号这种日期传进来时需调整至正常月底
                                if (this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                    while (DD > 0 && this.checkDate(YY + '-' + MM + '-' + thisDD + ' 00:00:00') !== true) {
                                        DD--;
                                        thisDD = DD < 10 ? '0' + DD : DD;
                                    }
                                }
                                // 月末の最終日が何曜日かを取得する
                                let thisWeek = this.formatDate(new Date(YY + '-' + MM + '-' + thisDD + ' 00:00:00'), 'week');
                                // 找到要求中最近的那个星期几
                                if (this.dayRuleSup < thisWeek) {
                                    DD -= thisWeek - this.dayRuleSup;
                                } else if (this.dayRuleSup > thisWeek) {
                                    DD -= 7 - (this.dayRuleSup - thisWeek)
                                }
                            }
                            // 時間値が10より小さいかどうかを判断する“５”に置き換える
                            DD = DD < 10 ? '0' + DD : DD;

                            // 循環“時”配列
                            goHour: for (let hi = hIdx; hi < hDate.length; hi++) {
                                let hh = hDate[hi] < 10 ? '0' + hDate[hi] : hDate[hi]

                                // 最大値になったの時に
                                if (nMin > mDate[mDate.length - 1]) {
                                    resetMin();
                                    if (hi == hDate.length - 1) {
                                        resetHour();
                                        if (Di == DDate.length - 1) {
                                            resetDay();
                                            if (Mi == MDate.length - 1) {
                                                resetMonth();
                                                continue goYear;
                                            }
                                            continue goMonth;
                                        }
                                        continue goDay;
                                    }
                                    continue;
                                }
                                // 循環"分"配列
                                goMin: for (let mi = mIdx; mi < mDate.length; mi++) {
                                    let mm = mDate[mi] < 10 ? '0' + mDate[mi] : mDate[mi];

                                    // 最大値になったの時に
                                    if (nSecond > sDate[sDate.length - 1]) {
                                        resetSecond();
                                        if (mi == mDate.length - 1) {
                                            resetMin();
                                            if (hi == hDate.length - 1) {
                                                resetHour();
                                                if (Di == DDate.length - 1) {
                                                    resetDay();
                                                    if (Mi == MDate.length - 1) {
                                                        resetMonth();
                                                        continue goYear;
                                                    }
                                                    continue goMonth;
                                                }
                                                continue goDay;
                                            }
                                            continue goHour;
                                        }
                                        continue;
                                    }
                                    // 循環"秒"配列
                                    goSecond: for (let si = sIdx; si <= sDate.length - 1; si++) {
                                        let ss = sDate[si] < 10 ? '0' + sDate[si] : sDate[si];
                                        // 現在の時間を追加する（時間の合法性は日付循環するの時にもう判断された）
                                        if (MM !== '00' && DD !== '00') {
                                            resultArr.push(YY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss)
                                            nums++;
                                        }
                                        // 条数がいっぱいの場合、循環を終了します。
                                        if (nums == 5) break goYear;
                                        // 最大値になったの時に
                                        if (si == sDate.length - 1) {
                                            resetSecond();
                                            if (mi == mDate.length - 1) {
                                                resetMin();
                                                if (hi == hDate.length - 1) {
                                                    resetHour();
                                                    if (Di == DDate.length - 1) {
                                                        resetDay();
                                                        if (Mi == MDate.length - 1) {
                                                            resetMonth();
                                                            continue goYear;
                                                        }
                                                        continue goMonth;
                                                    }
                                                    continue goDay;
                                                }
                                                continue goHour;
                                            }
                                            continue goMin;
                                        }
                                    } //goSecond
                                } //goMin
                            }//goHour
                        }//goDay
                    }//goMonth
                }
                return resultArr[0];
                // //100年間の結果の数を判断する
                // if (resultArr.length == 0) {
                //   this.resultList = ['条件を満たしていない結果！'];
                // } else {
                //   this.resultList = resultArr;
                //   if (resultArr.length !== 5) {
                //     this.resultList.push('最近100年内只有上面' + resultArr.length + '条结果！')
                //   }
                // }
            },
            // 配列内の数値のインデックスを計算するために使用されます
            getIndex(arr, value) {
                if (value <= arr[0] || value > arr[arr.length - 1]) {
                    return 0;
                } else {
                    for (let i = 0; i < arr.length - 1; i++) {
                        if (value > arr[i] && value <= arr[i + 1]) {
                            return i + 1;
                        }
                    }
                }
            },
            // "年"の配列を取得する
            getYearArr(rule, year) {
                this.dateArr[5] = this.getOrderArr(year, year + 100);
                if (rule !== undefined) {
                    if (rule.indexOf('-') >= 0) {
                        this.dateArr[5] = this.getCycleArr(rule, year + 100, false)
                    } else if (rule.indexOf('/') >= 0) {
                        this.dateArr[5] = this.getAverageArr(rule, year + 100)
                    } else if (rule !== '*') {
                        this.dateArr[5] = this.getAssignArr(rule)
                    }
                }
            },
            // "月"の配列を取得する
            getMonthArr(rule) {
                this.dateArr[4] = this.getOrderArr(1, 12);
                if (rule.indexOf('-') >= 0) {
                    this.dateArr[4] = this.getCycleArr(rule, 12, false)
                } else if (rule.indexOf('/') >= 0) {
                    this.dateArr[4] = this.getAverageArr(rule, 12)
                } else if (rule !== '*') {
                    this.dateArr[4] = this.getAssignArr(rule)
                }
            },
            // "日"の配列を取得する-主に日付ルール用
            getWeekArr(rule) {
                // 只有当日期规则的两个值均为“”时则表达日期是有选项的
                if (this.dayRule == '' && this.dayRuleSup == '') {
                    if (rule.indexOf('-') >= 0) {
                        this.dayRule = 'weekDay';
                        this.dayRuleSup = this.getCycleArr(rule, 7, false)
                    } else if (rule.indexOf('#') >= 0) {
                        this.dayRule = 'assWeek';
                        let matchRule = rule.match(/[0-9]{1}/g);
                        this.dayRuleSup = [Number(matchRule[1]), Number(matchRule[0])];
                        this.dateArr[3] = [1];
                        if (this.dayRuleSup[1] == 7) {
                            this.dayRuleSup[1] = 0;
                        }
                    } else if (rule.indexOf('L') >= 0) {
                        this.dayRule = 'lastWeek';
                        this.dayRuleSup = Number(rule.match(/[0-9]{1,2}/g)[0]);
                        this.dateArr[3] = [31];
                        if (this.dayRuleSup == 7) {
                            this.dayRuleSup = 0;
                        }
                    } else if (rule !== '*' && rule !== '?') {
                        this.dayRule = 'weekDay';
                        this.dayRuleSup = this.getAssignArr(rule)
                    }
                }
            },
            // "日"の配列を取得する-少しは日付のルール
            getDayArr(rule) {
                this.dateArr[3] = this.getOrderArr(1, 31);
                this.dayRule = '';
                this.dayRuleSup = '';
                if (rule.indexOf('-') >= 0) {
                    this.dateArr[3] = this.getCycleArr(rule, 31, false)
                    this.dayRuleSup = 'null';
                } else if (rule.indexOf('/') >= 0) {
                    this.dateArr[3] = this.getAverageArr(rule, 31)
                    this.dayRuleSup = 'null';
                } else if (rule.indexOf('W') >= 0) {
                    this.dayRule = 'workDay';
                    this.dayRuleSup = Number(rule.match(/[0-9]{1,2}/g)[0]);
                    this.dateArr[3] = [this.dayRuleSup];
                } else if (rule.indexOf('L') >= 0) {
                    this.dayRule = 'lastDay';
                    this.dayRuleSup = 'null';
                    this.dateArr[3] = [31];
                } else if (rule !== '*' && rule !== '?') {
                    this.dateArr[3] = this.getAssignArr(rule)
                    this.dayRuleSup = 'null';
                } else if (rule == '*') {
                    this.dayRuleSup = 'null';
                }
            },
            // "时"の配列を取得する
            getHourArr(rule) {
                this.dateArr[2] = this.getOrderArr(0, 23);
                if (rule.indexOf('-') >= 0) {
                    this.dateArr[2] = this.getCycleArr(rule, 24, true)
                } else if (rule.indexOf('/') >= 0) {
                    this.dateArr[2] = this.getAverageArr(rule, 23)
                } else if (rule !== '*') {
                    this.dateArr[2] = this.getAssignArr(rule)
                }
            },
            // "分"の配列を取得する
            getMinArr(rule) {
                this.dateArr[1] = this.getOrderArr(0, 59);
                if (rule.indexOf('-') >= 0) {
                    this.dateArr[1] = this.getCycleArr(rule, 60, true)
                } else if (rule.indexOf('/') >= 0) {
                    this.dateArr[1] = this.getAverageArr(rule, 59)
                } else if (rule !== '*') {
                    this.dateArr[1] = this.getAssignArr(rule)
                }
            },
            // "秒"の配列を取得する
            getSecondArr(rule) {
                this.dateArr[0] = this.getOrderArr(0, 59);
                if (rule.indexOf('-') >= 0) {
                    this.dateArr[0] = this.getCycleArr(rule, 60, true)
                } else if (rule.indexOf('/') >= 0) {
                    this.dateArr[0] = this.getAverageArr(rule, 59)
                } else if (rule !== '*') {
                    this.dateArr[0] = this.getAssignArr(rule)
                }
            },
            // 渡されたのmin-maxに基づいて順序付き配列を返します
            getOrderArr(min, max) {
                let arr = [];
                for (let i = min; i <= max; i++) {
                    arr.push(i);
                }
                return arr;
            },
            //ルールで指定された零散值に基づいて配列を返します
            getAssignArr(rule) {
                let arr = [];
                let assiginArr = rule.split(',');
                for (let i = 0; i < assiginArr.length; i++) {
                    arr[i] = Number(assiginArr[i])
                }
                arr.sort(this.compare)
                return arr;
            },
            // 一定の算数のルールに従って計算して１つの数値を返す
            getAverageArr(rule, limit) {
                let arr = [];
                let agArr = rule.split('/');
                let min = Number(agArr[0]);
                let step = Number(agArr[1]);
                while (min <= limit) {
                    arr.push(min);
                    min += step;
                }
                return arr;
            },
            // 規則によって、周期的な配列を返します
            getCycleArr(rule, limit, status) {
                // status--0から始めるかどうかを示す（1から開始）
                let arr = [];
                let cycleArr = rule.split('-');
                let min = Number(cycleArr[0]);
                let max = Number(cycleArr[1]);
                if (min > max) {
                    max += limit;
                }
                for (let i = min; i <= max; i++) {
                    let add = 0;
                    if (status == false && i % limit == 0) {
                        add = limit;
                    }
                    arr.push(Math.round(i % limit + add))
                }
                arr.sort(this.compare)
                return arr;
            },
            // 数値を比較する（Array.sortために）
            compare(value1, value2) {
                if (value2 - value1 > 0) {
                    return -1;
                } else {
                    return 1;
                }
            },
            // 格式化日期格式如：2017-9-19 18:04:33
            formatDate(value, type) {
                // 日付関連の値を計算する
                let time = typeof value == 'number' ? new Date(value) : value;
                let Y = time.getFullYear();
                let M = time.getMonth() + 1;
                let D = time.getDate();
                let h = time.getHours();
                let m = time.getMinutes();
                let s = time.getSeconds();
                let week = time.getDay();
                // type
                if (type == undefined) {
                    return Y + '-' + (M < 10 ? '0' + M : M) + '-' + (D < 10 ? '0' + D : D) + ' ' + (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
                } else if (type == 'week') {
                    // quartzの中に 1は日曜日です。
                    return week + 1;
                }
            },
            // 日付が存在するかどうかを確認する
            checkDate(value) {
                let time = new Date(value);
                let format = this.formatDate(time)
                return value === format;
            }
        }
    }
</script>

<style lang="scss" scoped>
  .content3 {
    height: calc(100vh - 270px);

    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  .sub-box {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    /deep/ .el-button {
      font-weight: 400;
      padding: 12px 12px;
      border-radius: 0px;
      border: 1px solid #f2f2f2;
      height: 30px;
      align-items: center;
      display: flex;
    }
  }

  .cz-box {
    /deep/ .el-button {
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      color: #FFFFFF;
      align-items: center;
      display: flex;
    }
  }
  .small-btn {
    color: white;
    height: 24px;
    line-height: 1px;
  }
</style>
